<!--
 * @Author: ChenYaJin
 * @Date: 2023-07-05 09:48:06
 * @LastEditors: ChenYaJin
 * @LastEditTime: 2023-07-12 17:49:36
 * @Description: 页面图片背景组件
-->
<template>
  <img :src="props.src" alt="" class="big-bg" :style="{ height: height }" />
</template>

<script setup lang="ts">
  defineOptions({
    name: 'BackgroundImage',
  })
  const props = defineProps({
    src: {
      type: String,
      default: '/images/header-bg.png',
    },
    height: {
      type: String,
      default: '22.5rem',
    },
  })
</script>

<style scoped>
  .big-bg {
    width: 100%;
    /* height: 40%; */
    position: absolute;
    top: 80px;
    left: 0;
    z-index: -1;
    object-fit: cover;
  }
</style>
