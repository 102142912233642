<!--
 * @Author: ChenYaJin
 * @Date: 2023-11-07 09:48:32
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-05 15:02:42
 * @Description: 顶部栏
-->
<template>
  <div class="headers-container">
    <BackgroundImage src="/images/admin/header-bg.png" height="350px" class="header-bg" />
    <span
      v-show="!menuCollapse"
      class="i-custom-shouqi icon-small cursor-pointer"
      @click="onSetMenuCollapse(true)"
    ></span>
    <span
      v-show="menuCollapse"
      class="i-custom-zhankai icon-small cursor-pointer"
      @click="onSetMenuCollapse(false)"
    ></span>
    <div class="flex items-center">
      <MLink to="/">
        <el-button type="primary" class="primary-gradient-bg">首页官网</el-button>
      </MLink>
      <el-dropdown trigger="hover">
        <span class="el-dropdown-link">
          <i class="i-ep-user-filled user-icon"></i>
          <span class="name-col">{{ enterpriseName }}</span>
          <i class="i-ep-arrow-down"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="onOpenAccount">账号编辑</el-dropdown-item>
            <el-dropdown-item @click="onLogout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useConfig } from '~/stores/config'
  import { useLoginStore } from '~/stores/modules/user/login'
  import { useUserStore } from '~/stores/modules/user/user'
  import { Enterprise } from '~/models/enterprise'

  defineOptions({
    name: 'AdminHeader',
  })

  const config = useConfig()
  const loginStore = useLoginStore()
  const userStore = useUserStore()
  const enterprise = ref<Enterprise>(new Enterprise({}))
  const enterpriseName = ref('')
  onMounted(() => {
    getEnterpriseInfo()
  })
  const getEnterpriseInfo = () => {
    userStore.getUserEnterpriseInfo().then((res) => {
      if (res && res.id) {
        enterprise.value = res
        enterpriseName.value = enterprise.value.name ?? ''
      } else {
        navigateTo({
          path: '/admin/user',
          query: {
            view: 'false',
          },
        })
      }
    })
  }
  const menuCollapse = computed(() => config.layout.menuCollapse)
  const onSetMenuCollapse = (collapse: boolean) => {
    config.setLayout('menuCollapse', collapse)
    setTimeout(() => {
      config.setNavTabsWidth()
    }, 350)
  }

  const onOpenAccount = () => {
    navigateTo({
      path: '/admin/account',
      query: {
        edit: 'true',
      },
    })
  }

  const onLogout = () => {
    ElMessageBox.confirm('<strong>是否退出当前登录！</strong>', '', {
      dangerouslyUseHTMLString: true,
      confirmButtonText: '取消',
      cancelButtonText: '确认',
      closeOnClickModal: false,
      showClose: false,
      confirmButtonClass: 'el-button is-plain',
      cancelButtonClass: 'el-button el-button--danger',
    })
      .then((e) => e)
      .catch(() => {
        loginStore.logout()
        navigateTo('/')
      })
  }
</script>

<style scoped lang="scss">
  .header-bg {
    @apply mt--80px;
  }
  .headers-container {
    @apply relative flex justify-between items-center w-full h-64px p-h-24px;
    .icon-small {
      width: var(--icon-small-size);
    }
  }
  .el-dropdown-link {
    @apply ml-10px min-w-100px flex-center;
    .name-col {
      @apply m-h-5px fw-bold;
    }
  }
  .user-icon {
    @apply font-s-18px;
    color: var(--primary-color);
  }
</style>
